<template>
  <canvas :id="id"></canvas>
</template>

<script>
import { Chart } from 'chart.js'
export default {
  props: {
    datos: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  computed: {
    id: {
      get(){
        return this.datos.id
      }
    },
    data: {
      get(){
        return this.datos.data
      }
    },
    options: {
      get(){
        return this.datos.options
      }
    },
    tipo: {
      get(){
        return this.datos.tipo
      }
    }
  },
  mounted () {

  },
  data() {
    return {
      barChart: null
    }
  },
  methods: {
    executeChart(){
      this.clearChart()
      const canvas = document.getElementById(this.id)
      const ctx = canvas.getContext('2d')
      this.barChart = new Chart(ctx, {
        type: this.tipo,
        data: this.data,
        options: this.options
      })
    },
    clearChart(){
      if (this.barChart != null) this.barChart.destroy()
    }
  },
  watch: {

  }
}
</script>

<style>

</style>